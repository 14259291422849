/* eslint-disable no-magic-numbers */
import { twMerge } from "@/helpers/twMerge";
import { PropTypes } from "prop-types";

import { StarIcon } from "./icons/StarIcon";

export const Rating = ({
	className,
	score,
	totalCount = 5,
	precision = 1,
	filledColor = "tw-text-[#FFD452]",
	...props
}) => (
	<div
		className={twMerge(
			// These are the default styles can be overridden by passing className
			// tw-text-xl - default size of the stars
			// tw-text-[#D5D5D6] - default color of the background stars
			"tw-flex tw-gap-0.5 tw-text-xl tw-text-[#D5D5D6]",
			className,
		)}
		role="img"
		aria-label={`Rating: ${score} out of ${totalCount} stars`}
		{...props}
	>
		{/* Create a no. of stars based on the totalCount */}
		{Array.from({ length: totalCount }).map((_, index) => (
			<div
				key={`rating-${index}`}
				role="presentation"
				className="tw-inline-flex"
			>
				{/* Background Star */}
				<StarIcon />
				<FilledStarIcon
					className={`tw-absolute ${filledColor}`}
					index={index}
					score={score}
					precision={precision}
				/>
			</div>
		))}
	</div>
);

Rating.propTypes = {
	className: PropTypes.string,
	totalCount: PropTypes.number,
	score: PropTypes.number.isRequired,
	precision: PropTypes.number,
	filledColor: PropTypes.string,
};

const FilledStarIcon = ({ index, score, precision, ...props }) => {
	// Only render when score is < current index + precision
	// score index precision              score index precision
	//   0  <  0  +  0.1   true             0  <  1  +  0.1   true  ...
	//   1  <  0  +  0.1   false            1  <  1  +  0.1   true  ...
	//   2  <  0  +  0.1   false            2  <  1  +  0.1   false ...
	if (score < index + precision) return null;

	// (precision)0.1 with 1/0.1 to 10(precisionCount)
	const precisionCount = 1 / precision;
	// if 4.8 => 0.8, if 4 => 0
	const precisionDecimal = roundWithPrecision(
		((score * precisionCount) % precisionCount) * precision,
		precisionCount,
	);
	// Render clipped star when score(4.8) minus precisionDecimal(0.8) = 4(index) => 5th star
	if (roundWithPrecision(score - precisionDecimal, precisionCount) === index) {
		// 0.8 * 100 => 80%
		const precisionPercentage = precisionDecimal * 100;

		return (
			<StarIcon
				{...props}
				style={{
					clipPath: `polygon(0% 0%,${precisionPercentage}% 0%,${precisionPercentage}% 100%,0% 100%)`,
					...props.style,
				}}
			/>
		);
	}

	return <StarIcon {...props} />;
};

FilledStarIcon.propTypes = {
	className: PropTypes.string,
	totalCount: PropTypes.number,
	index: PropTypes.number,
	score: PropTypes.number.isRequired,
	precision: PropTypes.number,
	filledColor: PropTypes.string,
	style: PropTypes.object,
};

// Round to the nearest value and maintain its precision solving js gotchas
// js gatchas => 4.6 - 4 = 0.5999999999999996 => 0.6
// js gatchas => 4.6 - 0.6 = 3.9999999999999996 => 4
// Nearest Value => 0.44 => 0.4, 0.45 => 0.5
const roundWithPrecision = (value, precision) =>
	Math.round(value * precision) / precision;
