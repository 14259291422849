import { Icon } from "../../../components/BaseComponents/Icon";

export const StarIcon = (props) => (
	<Icon viewBox="0 0 100 98" fill="none" {...props}>
		<path
			d="M63 38L100 38.86L69 62L81 98L50 76L18.5 98L31 61.5L0 39.34L37 39L50 0L63 38Z"
			fill="currentColor"
		/>
	</Icon>
);
